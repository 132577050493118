import React from "react";
import styled from "styled-components";
import img from "../assets/hero.jpg";
import Fade from "react-reveal/Fade";
import Logo from "../assets/logo_grey.png";
import { StaticImage } from "gatsby-plugin-image";
const AboutSection = () => {
  return (
    <Wrapper id="about">
      <div className="container">
        <Fade bottom>
          <Header>
            <div className="logo_letter">
              <img src={Logo} alt="" />
            </div>
            <h3>Miejsce gdzie liczysz sie tylko Ty.</h3>
          </Header>
        </Fade>
        <Fade bottom>
          <div className="split-container">
            <div className="split-photo">
              <StaticImage src="../assets/hero.jpg" alt="obiad" />
            </div>
            <div className="split-text">
              <div className="container">
                <h3>O nas</h3>
                <p>
                  Stragona to rodzinna restauracja od pokoleń prowadzona przez
                  Naszą rodzinę. Możemy pochwalić się wyjątkowo domową atmosferą
                  – co chyba charakteryzuje każdą małą miejscowość – kiedy gość
                  wchodzi do restauracji, kelner od progu wie jaką kawę
                  przygotować, a kuchnia nalewa ulubioną zupę. Smaczna i świeża
                  kuchnia to nasz priorytet! Jak kiedyś powiedział pewien z
                  naszych gości: „jesteście jedyną restauracją, w której kiedy
                  zamówię schabowego to za 3 minuty słyszę odgłos tłuczka do
                  kotletów dobiegający z kuchni” – nie ma dla nas lepszej
                  rekomendacji świeżości naszych dań!
                </p>
              </div>
              <div className="box">
                <CustomButton>ZOBACZ WIĘCEJ</CustomButton>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </Wrapper>
  );
};

const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 1px solid rgba(34, 44, 43, 0.15);
  color: #384140;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
  margin-top: 20px;
  &:hover {
    color: whitesmoke;
    background-color: #384140;
  }
`;

const Wrapper = styled.section`
  padding: 160px 72px;
  .box {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  .split-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
  .split-text {
    flex: 1;
    padding: 72px;
    font-family: "Josefin Slab", serif;
    max-width: 480px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      padding: 45px;
    }
    h3 {
      font-size: 44px;
    }
    p {
      font-size: 16px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 200;
      line-height: 1.75;
    }
  }
  .split-photo {
    flex: 1;
    display: flex;
    justify-content: center;
    div {
      width: 80%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const Header = styled.div`
  min-height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: column;
  .logo_letter {
    padding-bottom: 30px;
    img {
      width: 50px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 50px;
  }
  h3 {
    font-family: "Josefin Slab", serif;
    color: #384140;
    text-align: center;
    font-size: 52px;
    margin: 0 271px;
    @media (max-width: 1280px) {
      margin: 0 40px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export default AboutSection;
