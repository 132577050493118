import React from "react";
import styled from "styled-components";
import img from "../assets/party.jpg";
import Fade from "react-reveal/Fade";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
const PartySection = () => {
  return (
    <Wrapper>
      <div className="container">
        <Fade bottom>
          <div className="split-container">
            <div className="split-photo">
              <StaticImage src="../assets/party.jpg" alt="impreza" />
            </div>
            <div className="split-text">
              <h3>Przyjecia</h3>
              <p>
                Restauracja Stragona oferuje usługi organizacji przyjęć
                okolicznościowych Od wielu lat organizujemy wesela, imprezy
                integracyjne, chrzciny, komunie, stypy, rocznice, biesiady,
                spotkania biznesowe oraz konferencje.
              </p>
              <StyledLink to="/przyjecia">
                <CustomButton>ZOBACZ WIĘCEJ</CustomButton>
              </StyledLink>
            </div>
          </div>
        </Fade>
      </div>
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 1px solid rgba(34, 44, 43, 0.15);
  color: #384140;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
  margin-top: 20px;
  &:hover {
    color: whitesmoke;
    background-color: #384140;
  }
`;

const Wrapper = styled.section`
  padding: 160px 72px;
  @media (max-width: 768px) {
    padding: 0;
    padding-top: 72px;
  }
  .split-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
  .split-text {
    flex: 1;
    padding: 72px;
    font-family: "Josefin Slab", serif;
    @media (max-width: 768px) {
      padding: 45px;
    }
    h3 {
      font-size: 44px;
    }
    p {
      font-size: 16px;
      font-family: "Ubuntu", sans-serif;
      font-weight: 200;
      line-height: 1.75;
    }
  }
  .split-photo {
    flex: 1;
    display: flex;
    justify-content: center;
    div {
      width: 80%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const Header = styled.div`
  min-height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  h3 {
    font-family: "Josefin Slab", serif;
    color: #384140;
    text-align: center;
    font-size: 52px;
    margin: 0 271px;
    @media (max-width: 1280px) {
      margin: 0 101px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export default PartySection;
