import React from "react";
import styled from "styled-components";
import HeroImage from "../assets/hero2.jpg";
import { AiOutlineArrowRight } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

const HeroSection = () => {
  return (
    <HeroWrapper>
      <Fade bottom>
        <div className="text">
          <h1>Istniejemy od 1993 roku.</h1>
          <Link to="/noclegi">
            <button>
              Dowiedz się wiecej <AiOutlineArrowRight />
            </button>
          </Link>
        </div>
      </Fade>
    </HeroWrapper>
  );
};

const HeroWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  background-image: url(${HeroImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    width: 1px;
    height: 60px;
    background: grey;
    z-index: 3;
  }
  .text {
    @media (max-width: 1000px) {
      padding: 25px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
    }
    button {
      cursor: pointer;
      margin-top: 30px;
      border: 3px solid rgba(255, 255, 255, 0.6);
      background-color: transparent;
      color: #fefefe;
      padding: 20px 30px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      svg {
        margin-left: 5px;
      }
    }
    h1,
    h3 {
      color: #fefefe;
      font-family: "Josefin Slab", serif;
    }
    h1 {
      @media (max-width: 1000px) {
        font-size: 37px;
      }
      font-size: 60px;
      text-align: center;
    }
    h3 {
      font-weight: 300;
      font-size: 24px;
      text-align: center;
    }
  }
`;

export default HeroSection;
