import React from "react";
import styled from "styled-components";
import BurgerIMG from "../assets/burger3.jpg";
import { Link } from "gatsby";
const RestaurationSection = () => {
  return (
    <Wrapper>
      <div className="split-text">
        <div className="container">
          <div className="box">
            <h3>Nasza restauracja</h3>
          </div>
          <p>
            Dania w naszym menu to specjały kuchni polskiej, urozmaicone
            sezonowymi smakami i produktami regionalnymi. Nie brakuje w nim
            potraw dla sympatyków mięs, wegetarian i najmłodszych gości.
          </p>
          <div className="box">
            <StyledLink to="/restauracja">
              <CustomButton>ZOBACZ WIĘCEJ</CustomButton>
            </StyledLink>
          </div>
        </div>
      </div>
      <div className="split-image">
        <img src={BurgerIMG} alt="" />
      </div>
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CustomButton = styled.button`
  margin-top: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 1px solid #4a5251;
  color: #f0ede8;
  width: 180px;
  background: transparent;
  transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #cdcdcd;
    color: #384140;
  }
  a {
    color: #f0ede8;
    &:hover {
      color: #384140;
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
  width: 100vw;
  background-color: #384140;

  .split-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 50px;
    align-items: center;
    @media (max-width: 1258px) {
      padding: 45px;
    }
    .box {
      display: flex;
      justify-content: flex-start;
    }
    h3 {
      font-family: "Josefin Slab", serif;
      color: #f8f4ef;
      font-size: 44px;
      font-weight: 400;
    }
    p {
      color: #d3d5d5;
      display: flex;
      justify-content: center;
      font-family: "Ubuntu", sans-serif;
      font-weight: 200;
      font-size: 16px;
      line-height: 1.75;
      max-width: 480px;
    }
  }
  .split-image {
    flex: 1;
    @media (max-width: 1000px) {
    }
  }
  img {
    width: 100%;
    height: 100%;
    /* object-position: top center; */
    @media (max-width: 1280px) {
    }
  }
`;

export default RestaurationSection;
