import React from "react";
import AboutSection from "../components/AboutSection";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout";
import PartySection from "../components/PartySection";
import RestaurationSection from "../components/RestaurationSection";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hotel/Restauracja Stragona Strzegom</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <HeroSection />
      <AboutSection />
      <RestaurationSection />
      <PartySection />
    </>
  );
}
